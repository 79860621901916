
import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";
import { Defaults } from "@/helpers/Defaults";
//import { UserService } from "@/services/user-service";
@Component({
  name: "Logout",
})
export default class Logout extends Vue {
  user: IUser = new Defaults().user;
  //userService = new UserService();

  async beforeCreate(): Promise<void> {
    //let result = await this.userService.logout(this.user);
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("user");
    localStorage.removeItem("dashboardFilters");
    localStorage.removeItem("fLoadingPlanIds");
    await this.$router.push({
      name: "login",
    });
  }
}
