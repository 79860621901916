import { IDistrict } from "@/types/District";
import { ITransporter } from "@/types/Transporter";
import { IWarehouse } from "@/types/Warehouse";
import { IUser } from "@/types/User";
import { IDispatch } from "@/types/Dispatch";
import { ITruckDriver } from "@/types/TruckDriver";
import { ILoadingPlan } from "@/types/LoadingPlan";
import { ICommodity } from "@/types/Commodity";
import { IReceipt } from "@/types/Receipt";
import { IInventory } from "@/types/Inventory";
import { IActivity } from "@/types/Activity";
import { IProject } from "@/types/Project";
import { IRequisition } from "@/types/Requisition";
import { IRequestedCommodity } from "@/types/RequestedCommodity";
import { IOrganisation } from "@/types/Organisation";
import { IDonation } from "@/types/Donation";
import { IDonatedCommodity } from "@/types/DonatedCommodity";
import { IInstruction } from "@/types/Instruction";
import { IInstructedCommodity } from "@/types/InstructedCommoditiy";
import { IUnit } from "@/types/Unit";
import { IInstructedDispatch } from "@/types/InstructedDispatch";
import { IInstructedReceipt } from "@/types/InstructedReceipt";
import { IReceivedCommodity } from "@/types/ReceivedCommodities";
import { IDispatchedCommodity } from "@/types/DispatchedCommodity";
import { IServiceResponse } from "@/types/ServiceResponse";
import { IRequestParams } from "@/types/RequestParams";
import { IResponseData } from "@/types/ResponseData";

export class Defaults {
  get commodity(): ICommodity {
    return {
      commodityInventories: [],
      type: "",
      unit: "",
      id: 0,
      name: "",
      isArchived: false,
    };
  }
  get district(): IDistrict {
    return {
      id: 0,
      name: "",
      warehouses: [],
    };
  }
  get transporter(): ITransporter {
    return {
      id: 0,
      name: "",
      isArchived: false,
    };
  }
  get warehouse(): IWarehouse {
    return {
      organisation: this.organisation,
      organisationId: 0,
      id: 0,
      name: "",
      maxStorage: 0,
      districtId: 0,
      district: this.district,
    };
  }
  get organisation(): IOrganisation {
    return {
      contactPerson: "",
      id: 0,
      name: "",
      phoneNumber: "",
      warehouses: [],
    };
  }
  get driver(): ITruckDriver {
    return {
      id: 0,
      name: "",
      licence: "",
      isArchived: false,
    };
  }
  get loadingPlan(): ILoadingPlan {
    return {
      id: 0,
      createdOn: new Date(),
      updatedOn: new Date(),
      loadingPlanNumber: "",
      isActive: 0,
      quantity: 0,
      balance: 0,
      isArchived: false,
      startDate: new Date(),
      endDate: new Date(),
      commodityId: 0,
      commodity: this.commodity,
      warehouseId: 0,
      warehouse: this.warehouse,
      transporterId: 0,
      transporter: this.transporter,
      districtId: 0,
      district: this.district,
      userId: "",
      activityId: 0,
      activity: this.activity,
      dispatches: [],
    };
  }
  get dispatch(): IDispatch {
    return {
      id: 0,
      deliveryNote: "",
      finalDestinationPoint: "",
      quantity: 0,
      date: new Date(),
      truckNumber: "",
      isArchived: false,
      createdOn: new Date(),
      updatedOn: new Date(),
      driverId: 0,
      driver: this.driver,
      loadingPlanId: 0,
      loadingPlan: this.loadingPlan,
      dispatcherId: "",
    };
  }
  get receipt(): IReceipt {
    return {
      id: 0,
      remarks: "",
      finalDestinationPoint: "",
      quantity: 0,
      date: new Date(),
      isArchived: false,
      createdOn: new Date(),
      updatedOn: new Date(),
      dispatchId: 0,
      dispatch: this.dispatch,
      recipientId: "",
    };
  }
  get user(): IUser {
    return {
      id: "",
      userName: "",
      email: "",
      password: "",
      phoneNumber: "",
      roles: [],
    };
  }
  get inventory(): IInventory {
    return {
      id: 0,
      createdOn: new Date(),
      updatedOn: new Date(),
      quantity: 0,
      commodityId: 0,
      commodity: this.commodity,
      warehouseId: 0,
      warehouse: this.warehouse,
    };
  }
  get project(): IProject {
    return {
      activities: [],
      id: 0,
      name: "",
    };
  }
  get activity(): IActivity {
    return {
      id: 0,
      name: "",
      projectId: 0,
      project: this.project,
    };
  }
  get requisition(): IRequisition {
    return {
      affectedAreas: "",
      affectedHouseholds: 0,
      district: this.district,
      districtId: 0,
      createdOn: new Date(),
      updatedOn: new Date(),
      activity: this.activity,
      activityId: 0,
      isArchived: false,
      //instruction: this.instruction,
      requestedCommodities: [],
      requester: this.user,
      requesterId: "",
      id: 0,
    };
  }
  get requestedCommodity(): IRequestedCommodity {
    return {
      commodity: this.commodity,
      commodityId: 0,
      id: 0,
      quantity: 0,
      requisition: this.requisition,
      requisitionId: 0,
    };
  }
  get donation(): IDonation {
    return {
      createdOn: new Date(),
      date: new Date(),
      district: this.district,
      districtId: 0,
      donatedCommodities: [],
      donor: this.organisation,
      donorId: 0,
      driver: this.driver,
      driverId: 0,
      warehouseId: 0,
      warehouse: this.warehouse,
      goodsReceiveNote: "",
      id: 0,
      isArchived: false,
      recipient: this.user,
      recipientId: "",
      remarks: "",
      truckNumber: "",
      updatedOn: new Date(),
    };
  }
  get donatedCommodity(): IDonatedCommodity {
    return {
      commodity: this.commodity,
      commodityId: 0,
      donation: this.donation,
      donationId: 0,
      id: 0,
      quantity: 0,
    };
  }
  get instruction(): IInstruction {
    return {
      approvedBy: "",
      createdOn: new Date(),
      dispatches: [],
      district: this.district,
      districtId: 0,
      id: 0,
      isApproved: false,
      isArchived: false,
      instructedCommodities: [],
      remarks: "",
      requisitionId: 0,
      requisition: this.requisition,
      transporter: this.transporter,
      transporterId: 0,
      updatedOn: new Date(),
      userId: "",
      user: this.user,
      warehouse: this.warehouse,
      warehouseId: 0,
    };
  }
  get InstructedCommodity(): IInstructedCommodity {
    return {
      balance: 0,
      commodity: this.commodity,
      commodityId: 0,
      id: 0,
      instruction: this.instruction,
      instructionId: 0,
      quantity: 0,
    };
  }
  get instructedDispatch(): IInstructedDispatch {
    return {
      createdOn: new Date(),
      date: new Date(),
      deliveryNote: "",
      dispatchedCommodities: [],
      dispatcher: this.user,
      dispatcherId: "",
      driver: this.driver,
      driverId: 0,
      finalDestinationPoint: "",
      id: 0,
      instruction: this.instruction,
      instructionId: 0,
      isArchived: false,
      quantity: 0,
      truckNumber: "",
      updatedOn: new Date(),
    };
  }
  get dispatchedCommodity(): IDispatchedCommodity {
    return {
      instructedDispatch: this.instructedDispatch,
      instructedDispatchId: 0,
      commodity: this.commodity,
      commodityId: 0,
      id: 0,
      quantity: 0,
    };
  }
  get instructedReceipt(): IInstructedReceipt {
    return {
      createdOn: new Date(),
      date: new Date(),
      instructedDispatch: this.instructedDispatch,
      instructedDispatchId: 0,
      finalDestinationPoint: "",
      id: 0,
      isArchived: false,
      receivedCommodities: [],
      recipient: this.user,
      recipientId: "",
      remarks: "",
      updatedOn: new Date(),
    };
  }
  get receivedCommodity(): IReceivedCommodity {
    return {
      commodity: this.commodity,
      commodityId: 0,
      id: 0,
      instructedReceipt: this.instructedReceipt,
      instructedReceiptId: 0,
      quantity: 0,
    };
  }
  get unit(): IUnit {
    return {
      first: "",
      id: 0,
      second: "",
      value: 0,
    };
  }
  get serviceResponse(): IServiceResponse {
    return {
      data: [],
      isSuccess: false,
      message: "",
      time: new Date(),
    };
  }
  get requestParams(): IRequestParams {
    return { pageNumber: 1, pageSize: 10 };
  }
  get responseData(): IResponseData {
    return {
      count: 0,
      data: [],
      pendingCount: 0,
      requestParams: this.requestParams,
      totalStocks: 0,
    };
  }
}
